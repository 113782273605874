.app-notification-toast {
    background-color: var(--background-40);
    margin-top: 1rem !important;

    .toast-title,
    .toast-message {
        color: var(--font-color);
    }

    border-radius: 0.4rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.178) !important;
    animation: test 0.3s;

    .toast-close-button {
        color: var(--font-color);
    }

    &:hover {
        box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.425) !important;
    }
}

@keyframes test {
    0% {
        transform: translateY(-3rem);
    }
}
