markdown,
md-editor {
    h1 {
        font-size: 2rem !important;
        font-weight: bold !important;
        line-height: 2 !important;
    }

    p {
        font-size: 1.1rem !important;
        line-height: 1.5 !important;
    }

    em {
        font-weight: bold;
    }

    blockquote {
        border-left: 2px solid #ccc;
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;
        font-style: italic;
    }

    ul,
    ol {
        padding-left: 1rem;
    }

    ul {
        list-style-type: square;
    }
}
