.emd-form-group._state_error {
    .emd-label,
    .emd-checkbox__text {
        color: var(--color-danger-shade);
    }
}

.emd-label._required:after {
    content: ' *' !important;
    color: var(--color-danger) !important;
}
